import { Button } from '@mui/material';
import { INavbarLink } from 'containers';
import { Link as RouterLink } from 'react-router-dom';

export interface NavbarButtonProps {
  link: INavbarLink;
  isUnauthenticated?: boolean;
  isActive?: boolean;
  'data-cy': string;
  height?: string;
}

export function NavbarButton({
  link,
  isUnauthenticated,
  isActive,
  'data-cy': dataCy,
  height,
}: NavbarButtonProps) {
  return (
    <Button
      {...(link.type !== 'link' && {
        disableElevation: true,
      })}
      variant={link?.variant || 'text'}
      color={link?.color || 'inherit'}
      sx={{
        ...(link.textColor && { color: link.textColor }),
        ...(isUnauthenticated && { marginLeft: 1 }),
        textTransform: 'none',
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        padding: '0px 20px',
        gap: '8px',
        position: 'relative',
        '&::after': isActive
          ? {
              content: '""',
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              height: '4px',
              backgroundColor: 'secondary.main',
            }
          : {},
        '&:hover': !isActive
          ? {
              backgroundColor: 'primary.dark',
            }
          : {},
        '&:not(:hover)::after': isActive
          ? {
              backgroundColor: 'secondary.main',
            }
          : {},
      }}
      {...(link?.action && { onClick: link.action })}
      {...(link?.route && {
        to: link.route,
        component: RouterLink,
      })}
      {...(link?.target && {
        target: link.target,
      })}
      data-cy={dataCy}
      startIcon={link.icon}
    >
      {link.label}
    </Button>
  );
}
