// external
import React from 'react';
import { Box, Paper, Typography, SxProps, Theme } from '@mui/material';
import { IconType } from 'react-icons';
import { hexToRGB } from 'utils';
import { useMobileMediaQuery } from 'hooks';
import { PageContentCTAs } from './PageContentCTAs';

export interface PageContentAction {
  label: string | undefined;
  action: () => Promise<void> | void;
  icon?: IconType;
  color:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
  'data-cy'?: string;
}

interface PageContentProps {
  title: string | undefined;
  subtitle: string | undefined;
  icon?: IconType;
  iconColor?: string;
  actions?: PageContentAction[];
  containerStylings?: SxProps<Theme>;
}

export const PageContent: React.FC<PageContentProps> = ({
  title,
  subtitle,
  icon,
  iconColor,
  actions,
  containerStylings = {},
}) => {
  const isMobile = useMobileMediaQuery();
  const Icon = icon;

  const centeredCircleStyles = {
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <Paper
      variant={isMobile ? undefined : 'outlined'}
      elevation={0}
      sx={{
        width: 'auto',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        py: 6,
        px: 3,
        borderRadius: isMobile ? 0 : '4px',
        ...containerStylings,
      }}
    >
      {icon && iconColor && (
        <Box
          sx={{
            width: '82px',
            height: '82px',
            backgroundColor: `rgba(${hexToRGB(iconColor)}, 0.08)`,
            ...centeredCircleStyles,
            m: 1.5,
          }}
        >
          <Box
            sx={{
              width: '66px',
              height: '66px',
              backgroundColor: `rgba(${hexToRGB(iconColor)}, 0.08)`,
              ...centeredCircleStyles,
              fontSize: '32px',
            }}
          >
            {Icon && <Icon color={iconColor} />}
          </Box>
        </Box>
      )}
      <Typography variant="h6" component="h2">
        {title}
      </Typography>
      <Typography
        variant="body2"
        component="h3"
        sx={{ mt: 1, textAlign: 'center' }}
      >
        {subtitle}
      </Typography>
      <PageContentCTAs actions={actions} />
    </Paper>
  );
};
