// external
import {
  Card,
  CardProps,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import { useMobileMediaQuery } from 'hooks';
import React, { useCallback } from 'react';
import { IconType } from 'react-icons';
import { Link } from 'react-router-dom';

interface PageSidebarProps extends CardProps {
  items: {
    primaryText: string | undefined;
    icon: IconType;
    path: string;
    selected: boolean;
    dataCyLabel: string;
  }[];
}

export const PageSidebar: React.FC<PageSidebarProps> = ({ items, ...rest }) => {
  const theme = useTheme();
  const isMobile = useMobileMediaQuery();

  const renderButtons = useCallback(() => {
    return items?.map((item, index) => {
      const Icon = item.icon;

      return (
        <ListItemButton
          component={Link}
          to={item.path}
          selected={item.selected}
          key={`page-sidebar-item-${index}`}
          data-cy={item.dataCyLabel}
        >
          <ListItemIcon sx={{ fontSize: 20 }}>
            <Icon />
          </ListItemIcon>
          <ListItemText>
            <Typography variant="body2">{item.primaryText}</Typography>
          </ListItemText>
        </ListItemButton>
      );
    });
  }, [items]);

  return (
    <Card variant={isMobile ? undefined : 'outlined'} elevation={0} {...rest}>
      <List
        sx={{
          p: 0,
          '&& .Mui-selected': {
            backgroundColor: theme.palette.secondary.dark,
            'svg, p': {
              color: 'white',
            },
            '&:hover': {
              backgroundColor: theme.palette.secondary.dark,
              'svg, p': {
                color: 'white',
              },
            },
          },
          '&& .MuiListItemIcon-root': {
            minWidth: '36px',
          },
        }}
      >
        {renderButtons()}
      </List>
    </Card>
  );
};
