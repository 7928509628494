import { Box } from '@mui/material';
import { PageContentCTA } from './PageContentCTA';
import { PageContentAction } from '.';

interface PageContentCTAsProps {
  actions: PageContentAction[] | undefined;
}

export function PageContentCTAs({ actions = [] }: PageContentCTAsProps) {
  return (
    <Box
      sx={(theme) => ({
        width: '100%',
        mt: 3,
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
          width: 'fit-content',
        },
      })}
    >
      {actions?.map((action, index) => (
        <PageContentCTA
          key={`page-content-action-${index}`}
          {...action}
          sx={{ ml: index !== 0 ? 1 : 0 }}
        />
      ))}
    </Box>
  );
}
