// external
import React, { useCallback } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { IconType } from 'react-icons';
import { DropdownMenu } from 'components/DropdownMenu';
import { useMobileMediaQuery } from 'hooks';

interface IPageHeaderAction {
  label: string;
  action: () => Promise<void> | void;
  icon?: IconType;
  color?: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  variant?: 'outlined' | 'contained';
}
interface PageHeaderProps {
  header: string | undefined;
  subheader: string | undefined;
  actions?: IPageHeaderAction[];
  onDropdownButtonClick?: () => void;
  dropdown?: {
    actions: IPageHeaderAction[];
    label: string;
  };
  width?: '50%' | '70%';
}

export const PageHeader: React.FC<PageHeaderProps> = ({
  header,
  subheader,
  actions,
  onDropdownButtonClick,
  dropdown,
  width = '70%',
}) => {
  const isMobile = useMobileMediaQuery();

  const hasActions = !!actions;

  const renderActions = useCallback(() => {
    if (hasActions) {
      return (
        <Box
          sx={{
            width: isMobile ? '100%' : 'auto',
            display: 'flex',
            justifyContent: 'flex-end',
            mt: isMobile ? 2 : 0,
          }}
        >
          {actions.map((action, index) => {
            const Icon = action.icon;

            return (
              <Button
                data-cy="components_Page_PageHeader_action-button"
                key={`page-header-action-${index}`}
                startIcon={Icon && <Icon />}
                onClick={action.action}
                color={action.color}
                variant={action.variant || 'contained'}
                sx={{ width: isMobile ? '100%' : 'auto', whiteSpace: 'nowrap' }}
              >
                {action.label}
              </Button>
            );
          })}
        </Box>
      );
    }
  }, [actions, isMobile, hasActions]);

  const renderDropdown = () => {
    if (dropdown) {
      const { label, actions } = dropdown;

      return (
        <DropdownMenu
          containerSx={{
            mt: isMobile ? 2 : 0,
            ml: hasActions ? 2 : 0,
            width: isMobile ? '100%' : 'auto',
          }}
          label={label}
          onDropdownButtonClick={onDropdownButtonClick}
          actions={actions}
        />
      );
    }
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        pt: isMobile ? 3 : 8,
        pb: isMobile ? 2 : 3,
        boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.06)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
      }}
    >
      <Box
        sx={{
          width: isMobile ? '95%' : width,
          maxWidth: '1440px',
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'space-between',
          alignItems: isMobile ? 'flex-start' : 'flex-end',
          mx: 'auto',
          px: 1.5,
        }}
      >
        <Box>
          <Typography component="h1" variant="h4">
            {header}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            {subheader}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            alignSelf: 'flex-end',
            width: isMobile ? '100%' : 'auto',
          }}
        >
          {renderActions()}
          {renderDropdown()}
        </Box>
      </Box>
    </Box>
  );
};
