import { Button, SxProps, Theme } from '@mui/material';
import { PageContentAction } from '.';

interface PageContentCTAProps extends PageContentAction {
  sx: SxProps<Theme>;
}

export function PageContentCTA({
  label,
  action,
  icon,
  color,
  sx = {},
  'data-cy': dataCy,
}: PageContentCTAProps) {
  const ActionIcon = icon;

  return (
    <Button
      startIcon={ActionIcon && <ActionIcon />}
      onClick={action}
      color={color}
      variant="contained"
      fullWidth
      sx={{ ...sx }} // spread passed stylings to also allow local stylings
      data-cy={dataCy}
    >
      {label}
    </Button>
  );
}
