// external
import React from 'react';
import { Box, Card, Typography, useTheme, SxProps, Theme } from '@mui/material';
import { hexToRGB } from 'utils';
import { useMobileMediaQuery } from 'hooks';

interface PageCardProps {
  title: string;
  subtitle: string;
  color?: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  icon?: React.ElementType<{
    sx: SxProps<Theme>;
    color: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  }>;
  iconSx?: SxProps<Theme>;
  textAlign?: 'start' | 'end' | 'center';
  cardSx?: SxProps<Theme>;
}

export const PageCard: React.FC<PageCardProps> = ({
  title,
  subtitle,
  color = 'info',
  icon,
  iconSx = {},
  textAlign = 'start',
  cardSx = {},
}) => {
  const theme = useTheme();
  const isMobile = useMobileMediaQuery();
  const Icon = icon;

  return (
    <Card
      elevation={0}
      sx={{
        width: 'auto',
        backgroundColor: `rgba(${hexToRGB(theme.palette[color].main)}, 0.08)`,
        p: isMobile ? 2 : 3,
        mx: isMobile ? 0 : 3,
        mb: isMobile ? 3 : 0,
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        ...cardSx,
      }}
    >
      {Icon && (
        <Icon
          color={color}
          sx={{
            fontSize: 60,
            mr: isMobile ? 0 : 4,
            ml: isMobile ? 0 : 1,
            mb: isMobile ? 2 : 0,
            ...iconSx,
          }}
        />
      )}
      <Box sx={{ textAlign: textAlign }}>
        <Typography variant="h6" color={`${color}.dark`} sx={{ mb: 1 }}>
          {title}
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 400 }}>
          {subtitle}
        </Typography>
      </Box>
    </Card>
  );
};
