// external
import React, { PropsWithChildren } from 'react';
import { Box, SxProps, Theme } from '@mui/material';
import { useMobileMediaQuery } from 'hooks';

interface PageWrapperProps {
  sx?: SxProps<Theme>;
}

export const PageWrapper: React.FC<PropsWithChildren<PageWrapperProps>> = ({
  children,
  sx = {},
}) => {
  const isMobile = useMobileMediaQuery();

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f5f5f5',
        py: isMobile ? 0 : 3,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
