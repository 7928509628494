/**
 * This component is a default card design for modals with content. It needs to be wrapped around with MUI Modal component with open and handleClose provided.
 *
 * @children Children of this Modal will be displayed as it's content, between actions and header.
 * @handleClose Function that handles closing the modal, both by the close button and clicking the overlay. If not provided, the modal will be unclosable from the card.
 * @title String displayed in the header.
 * @description Smaller script displayed bellow the header. Can be omitted.
 * @width Width of the modal, if it is to differ from the default.
 * @headerLineColor The color of the cards bottom border. Default is organizations secondary color.
 * @childrenSx SX props to apply custom formatting to the box surrounding modal's content.
 * @isLoading If modal buttons are suppose to display loading behaviour, this boolean will control that.
 * @actions Array of actions that can be performed in this modal that will be displayed as buttons on the bottom of it. One action renders a button on the right, more will be spread evenly.
 * If instead of a particular action, an array of actions is displayed, they will be rendered together, inside a box.
 * Actions can be omitted. If so, nothing will render bellow children. This can be used to render modal actions inside children, if neccessary.
 * Schema of the action object can be found in ActionButton component.
 *
 */

//external
import React, { PropsWithChildren, ReactNode } from 'react';
import Close from '@mui/icons-material/Close';
import {
  Box,
  Card,
  IconButton,
  Typography,
  SxProps,
  Theme,
  useTheme,
  Stack,
} from '@mui/material';

//types
import { ActionButton, IButtonAction } from 'components/ActionButton';

import { useMobileMediaQuery } from 'hooks';

interface ModalCardProps {
  handleClose?: () => void;
  title: string | ReactNode;
  description?: string;
  isLoading?: boolean;
  actions?: (IButtonAction | IButtonAction[])[];
  width?: string;
  height?: string;
  childrenSx?: SxProps<Theme>;
  icon?: React.ReactElement;
  headerLineColor?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
  'data-cy'?: string;
  closeIconButtonDataCy?: string;
}

export const ModalCard: React.FC<PropsWithChildren<ModalCardProps>> = ({
  handleClose,
  children,
  title,
  description,
  isLoading,
  actions,
  icon,
  height,
  headerLineColor = 'secondary',
  width = '700px',
  childrenSx = { p: 2, overflowY: 'auto' },
  'data-cy': dataCy,
  closeIconButtonDataCy,
}) => {
  const theme = useTheme();
  const isMobile = useMobileMediaQuery();

  return (
    <Card
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxHeight: { xs: '100vh', md: height ?? '90vh' },
        height: { xs: '100%', md: height ?? 'max-content' },
        width: { xs: '100vw', md: width },
        borderTop: `8px solid ${theme.palette[headerLineColor].main}`,
        display: 'flex',
        flexDirection: 'column',
        ...(isMobile && {
          borderRadius: '0',
          height: '100%',
          justifyContent: '',
        }),
      }}
      data-cy={dataCy}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 2,
          borderBottom: `1px solid ${theme.palette.divider}`,
          boxSizing: 'border-box',
        }}
      >
        <Box>
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            {icon}
            <Typography variant="h6" component="p">
              {title}
            </Typography>
          </Stack>
          {description && (
            <Typography variant="body1" color="text.secondary">
              {description}
            </Typography>
          )}
        </Box>
        {handleClose && (
          <IconButton
            onClick={handleClose}
            disabled={isLoading}
            sx={{ p: 0, my: 1 }}
          >
            <Close data-cy={closeIconButtonDataCy} />
          </IconButton>
        )}
      </Box>

      <Box sx={childrenSx}>{children}</Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column-reverse' : 'row',
          justifyContent: actions?.length === 1 ? 'flex-end' : 'space-between',
          alignItems: 'flex-end',
          flex: '1 1 0',
          px: 3,
          py: 2,
        }}
      >
        {actions?.map((action, index) => {
          if (Array.isArray(action)) {
            return (
              <Box
                sx={{
                  display: 'flex',
                  flex: '1 1 0',
                  flexDirection: isMobile ? 'column-reverse' : 'row',
                  gap: isMobile ? 0 : 3,
                  width: isMobile ? '100%' : 'fit-content',
                  justifyContent: 'flex-end',
                }}
              >
                {action.map((action, actionIndex) => (
                  <ActionButton
                    isLoading={isLoading}
                    buttonAction={action}
                    key={`modal-button-${actionIndex}`}
                  />
                ))}
              </Box>
            );
          } else {
            return (
              <ActionButton
                isLoading={isLoading}
                buttonAction={action}
                key={`modal-button-${index}`}
              />
            );
          }
        })}
      </Box>
    </Card>
  );
};
