import { Headset } from '@mui/icons-material';
import { Button } from '@mui/material';
import { INavbarLink } from 'containers';

export interface MenuProfileCardProps {
  supportLink: INavbarLink;
}

export const NavbarSupportButton = ({ supportLink }: MenuProfileCardProps) => {
  const { label, action } = supportLink;
  return (
    <Button
      onClick={action}
      variant="outlined"
      startIcon={<Headset />}
      sx={{
        height: 'min-content',
        display: 'flex',
        alignItems: 'center',
        padding: '8px 14px',
        border: '1px solid #e0e0e0',
        borderRadius: '24px',
        backgroundColor: '#fff',
        fontSize: '14px',
        fontWeight: '400',
        color: '#333',
        '&:hover': {
          backgroundColor: '#fff',
          borderColor: '#e0e0e0',
        },
      }}
    >
      {label}
    </Button>
  );
};
